
.containerError {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: #f8f8f8;
  }
  
  .error-code {
    font-size: 10rem;
    font-weight: 700;
    color: #4c1e6b;
    margin-bottom: 10px;
    
  }
  
  .error-message {
    font-size: 2rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .back-button {
    background-color: #ff8c00;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #ff9900;
  }
  