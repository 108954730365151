body {
    font-family: Arial, sans-serif;
    background-color: #fff;
    color: #000;
}

.create-your-sign {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f8f8f8;
}

.header {
    text-align: center;
}

.header h1 {
    font-size: 36px;
    font-weight: bold;
    text-shadow: 2px 2px 5px #000;
    margin-bottom: 10px;
}

.benefits {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 18px;
    color: #2b8c00;
}

.description p {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
}

.description ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
    font-size: 14px;
}

.input-section {
    margin-top: 20px;
    text-align: center;
}

#custom-text {
    width: 80%;
    padding: 10px;
    font-size: 24px;
    color: red;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.settings {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.settings div {
    text-align: left;
}

#font, #color, #height, #width {
    width: 100px;
    margin-top: 5px;
    padding: 5px;
}

.order-section {
    margin-top: 20px;
    text-align: center;
}

.order-btn {
    background-color: red;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.order-btn:hover {
    background-color: darkred;
}

.transportSection{
    height: 40vh ;
}

.transportImg{
    max-height: 40vh;
    width: 110%;
    overflow: hidden;
    object-fit: contain;
    
}