.bg-image {
    background-image: url('../../assets/images/background.jpg');
    background-size: 100% 100%; 
    background-position: center; 
    background-repeat: no-repeat; 
    height: 100vh; 
    width: 100%;
  }

/* Основен стил за линка */
.custom-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: #6606a5;
  text-decoration: none;
  font-size: 1.2rem;
  border: 2px solid#7f38ae;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  
}

.custom-link:hover {
  background-color: #7f38ae;
  color: white;
  
}
.transparent-background {
  background-color:rgba(240, 240, 240, 0.5); 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); 
}

  .custom-height {
    height: 545px; 
    overflow: hidden; 
    padding: 0px;
  }

  .rounded-circle {
    width: 100%;
    height: auto;
    flex: 1;
    object-fit: cover;
    border: 3px solid white;
  }

  .flexClass{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:0;
    flex-wrap: wrap;
    }
.circleDiv {
    width: 100px;
    height: 100px;
    background-color: #ccc;
    border-radius: 50%;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0;
}
 .circleDiv img{
    max-width: 100%; 
    max-height: 100%;
    object-fit: contain;
    border-radius: 50%;
    transition: transform 0.3s ease;

  }
  .circleDiv:hover img {
    transform: scale(1.1); 
}
  .responsive-image {
    max-width: 100%;
    max-height: 100%; 
    object-fit: contain;
  }

.image-container {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.overlay-image {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: auto;
  opacity: 0;
  animation: fadeOverlay 12s infinite;
  text-align: center;
}


.overlay1 {
  top: 50%;
  left:48%;
  animation-delay: 0s;
}

.overlay2 {
  animation-delay: 2s;
}

.overlay3 {
  animation-delay: 4s;
  top: 56%;
  left:40%;
  width: 18%;
  height: auto;
}

.overlay4 {
  animation-delay: 6s;
  top: 53%;
  left:59%;
  width: 25%;
  height: auto;
}

.overlay5 {
  animation-delay: 8s;
  top: 50%;
  left:77%;
  width: 10%;
  height: auto;
}

.overlay6 {
  animation-delay: 10s;
  top:51%;
  left:48%;
}

@keyframes fadeOverlay {
  0%, 14.99%, 100% {
    opacity: 0;
  }
  15%, 30% {
    opacity: 1;
  }
  30.01%, 100% {
    opacity: 0;
  }

}

@media (max-width: 768px) {
  .custom-height {
    height: 450px; 
  }
}

.bi-caret-right-fill {
  font-size: 2em; /* Начален размер на стрелката */
  transition: font-size 0.3s ease; /* Плавен преход при промяна на размера */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Добавя сянка при hover */

}

.bi-caret-right-fill:hover {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Добавя сянка при hover */
}

