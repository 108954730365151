.product-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 100%;
  }
  
  .product-card:hover {
    transform: scale(1.05); /* Увеличава картата при ховър */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Добавя сянка при ховър */
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .separator {
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #000, rgba(0, 0, 0, 0));
    margin: 10px 0;
  }
  
  .card-text {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .card-footer {
    background-color: transparent;
    border-top: none;
  }
  
  .no-articles {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    text-align: center;         
    margin-top: 20px;            
    color: #555;                 
    font-size: 35px;             
    font-weight: bold;           
    padding: 10px;               
    border: 1px solid #ddd;      
    border-radius: 5px;          
    background-color: #f9f9f9;  
  }

.img-container {
  width: 90%;
  height: 40vh;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 10px;
}

.card-img-top {
  width: 100%;
  height: auto;
  object-fit: cover; 
  object-position: center; 
}
.favorite-icon {
  background: transparent;
  border: none;
  color: white; 
  transition: color 0.3s ease;
}

.favorite-icon:hover {
  color: red !important;  
  border: none;
}

.favorite-icon.active {
  color: red !important; 
  border: none;
}

