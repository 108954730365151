body {
  font-family: "Poppins", sans-serif;
}

.bg-custom-purple {
  background-color: rgba(101, 6, 165, 0.128);
  background-color: #6606a5;
  box-shadow: 6px 29px 11px -11px rgba(0, 0, 0, 0.4);
  border: none;
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link,
.navbar-custom .nav-dropdown,
.search-icon {
  color: white;
}

.navbar-custom .nav-link.disabled {
  color: #cccccc;
}

.search-icon {
  border: none;
  font-weight: 300;
}

.dropdown-hover .dropdown-toggle::after {
  display: none;
}

.navbar-nav .nav-link,
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:active,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: white !important; 
  text-decoration: none !important;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  border-bottom: 1px solid #d7d3d3;
}

.dropdown-hover .dropdown-menu {
  background-color: rgba(211, 211, 211, 0.7);
  border-radius: 0.375rem;
}

.dropdown-header {
  font-size: 1.25em;
  color: #000;
  padding: 0.5rem 1rem;
  background-color: transparent;
}

.logo {
  width: 100px;
  height: auto;
}

.nav-item.dropdown:hover > .dropdown-menu {
  display: flex;
  margin-top: 0;
  opacity: 1;
}

.dropdown-menu {
  display: none;
  flex-direction: row;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .vertical-divider {
    border-left: none;
    border-top: 2px solid #000;
    display: block;
    width: 100%;
    margin: 0.5rem 0;
  }
}

/* Стили за иконата на количката */
.cart-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

/* Брояч за артикулите в количката */
.cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}
